<template>
  <div>
    <!-- <title-bar :title-stack="titleStack" /> -->
    <hero-bar>
      Pengajuan Sidang
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="'Pengajuan Sidang'"
          icon="account-edit"
          class="tile is-child"
        >

          <p style="font-size: 18px; margin: 18px; text-align: center;">
            Sebelum melakukan pengajuan <b>sidang</b> <br>
            mohon untuk melengkapi informasi mahasiswa di halaman
            <router-link :to="{name:'profile'}">
              profil
            </router-link>
            ,<br>
            Harap isi sesuai dengan <b>Akte Lahir dan Ijazah terakhir</b><br><br>

            Pengajuan Sidang hanya untuk proposal yang telah disetujui
          </p>

          <form @submit.prevent="submit">
            <b-field label="Pilih Proposal" horizontal>
              <v-select v-model="form.proposal_id" label="name" :options="accepted_proposals" :reduce="x => x.id"></v-select>
            </b-field>
            <hr />
            <b-field horizontal>
              <b-button
                type="is-primary"
                :loading="isLoading"
                native-type="submit"
                >Submit</b-button
              >
            </b-field>
          </form>
        </card-component>
      </tiles>

      <div class="card has-table has-mobile-sort-spaced">
        <header class="card-header">
          <p class="card-header-title">
            <b-icon icon="account-multiple" custom-size="default" />
            Status Pengajuan Sidang
          </p>
        </header>
        <div class="card-content">
          <b-table
            :checked-rows.sync="checkedRows"
            :loading="isLoading"
            :striped="true"
            :hoverable="true"
            :data="sidang"
          >
            <b-table-column label="Proposal" field="proposal" sortable v-slot="props">
              {{ props.row.judul }}
            </b-table-column>
            <b-table-column label="Tanggal" field="tanggal" sortable v-slot="props">
              {{ props.row.created_at }}
            </b-table-column>
            <b-table-column label="Status" field="status" sortable v-slot="props">
              {{ props.row.status}} <br> <b v-if="props.row.note2" >Catatan:</b> {{props.row.note2}}
            </b-table-column>

            <section slot="empty" class="section">
              <div class="content has-text-grey has-text-centered">
                <template v-if="isLoading">
                  <p>
                    <b-icon icon="dots-horizontal" size="is-large" />
                  </p>
                  <p>Fetching data...</p>
                </template>
                <template v-else>
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large" />
                  </p>
                  <p>Nothing's here&hellip;</p>
                </template>
              </div>
            </section>
          </b-table>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import axios from 'axios'
// import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'Form',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    // TitleBar,
    vSelect
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      form: this.getClearFormObject(),
      createdReadable: null,
      isProfileExists: false,
      checkedRows: [],
      proposals: [],
      sidang: []
    }
  },
  computed: {
    accepted_proposals () {
      const filter = this.proposals.filter(x => parseInt(x.status_id) === 2)
      const maping = filter.map(x => { return { id: x.id, name: x.judul } })

      return maping
    }
  },
  watch: {
    id (newValue) {
      this.isProfileExists = false

      if (!newValue) {
        this.form = this.getClearFormObject()
      } else {
        this.getData()
      }
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getClearFormObject () {
      return {
        proposal_id: null
      }
    },
    getData () {
      axios
        .get('/mahasiswa_proposals')
        .then((r) => {
          this.isLoading = false
          if (r.data && r.data.data) {
            this.proposals = r.data.data
          }
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })

      axios
        .get('/mahasiswa_sidang')
        .then((r) => {
          this.isLoading = false
          if (r.data && r.data.data) {
            this.sidang = r.data.data
          }
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    },
    handleResponse (r) {
      console.log(r.data)
      this.isLoading = false

      this.$buefy.snackbar.open({
        message: (typeof r.data.message === 'object') ? `error: ${r.data.message.join(', ')}` : r.data.message,
        type: r.data.status === 'ok' ? 'is-primary' : 'is-danger',
        queue: false
      })
      if (r.data.status === 'ok') {
        // this.$router.push({ name: 'pengajuan_sidang_mahasiswa' })
        this.getData()
      }
    },
    handleError (e) {
      this.isLoading = false
      this.$buefy.toast.open({
        message: `Error: ${e.message}`,
        type: 'is-danger',
        queue: false
      })
    },
    submit () {
      const data = this.form

      if (!data.proposal_id) {
        this.handleError({ message: 'Pilih Proposal terlebih dahulu' })

        return false
      }

      this.isLoading = true

      axios
        .post('/mahasiswa_sidang', data)
        .then(this.handleResponse)
        .catch(this.handleError)
    }
  }
}
</script>
